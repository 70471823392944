/**
 * @flow
 */

import { graphql } from 'gatsby';

import MarkdownPage from 'components/MarkdownPage';
import PropTypes from 'prop-types';
import React from 'react';
import Layout from 'components/layout';
import { createLinkDocs } from 'utils/createLink';
import { sectionListFortolkning } from 'utils/sectionList';
// import Flex from 'components/Flex';
import { colors, fonts, media } from 'theme';

const Fortolkning = ({ data, location }) => {
  // HACK The injected location prop doesn't update when hash changes
  // This might be a gatsby issue, or a react-router/history issue,
  // Or we might be using either library incorrectly.
  // For now this patch keeps the hash in sync by JIT copying it from window.
  // The undefined check prevents us from breaking on production build.
  if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
    location.hash = window.location.hash;
  }

  return (
    <Layout location={location}>
      <MarkdownPage
        enableScrollSync
        createLink={createLinkDocs}
        location={location}
        markdownRemark={data.markdownRemark}
        sectionList={sectionListFortolkning}
        titlePostfix=" - Lejeloven.dk"
        header={
          <header css={{ textAlign: 'center' }}>
            {/* <Flex type="header" halign="space-between" valign="baseline"> */}
            <h1
              css={{
                color: colors.dark,
                marginBottom: 0,
                marginTop: 40,
                ...fonts.header,

                [media.size('medium')]: {
                  marginTop: 60
                },

                [media.greaterThan('large')]: {
                  marginTop: 80
                }
              }}
              id="indledning">
              Lejeloven
            </h1>
            <br />
            <h2>
              - frit oversat med hjælp fra Lejerens&nbsp;Frie&nbsp;Retshjælp
            </h2>
          </header>
        }
      />
    </Layout>
  );
};

Fortolkning.propTypes = {
  data: PropTypes.object.isRequired
};

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        next
        prev
      }
      fields {
        path
        slug
      }
    }
  }
`;

export default Fortolkning;
